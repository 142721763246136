export const GET_PRODUCTS = 'GET_PRODUCTS';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const SEARCH_PRODUCTS  = 'SEARCH_PRODUCTS ';
export const EDIT_PRODUCT_SUCCESS = "EDIT_PRODUCT_SUCCESS";
export const EDIT_PRODUCT_FAILURE = "EDIT_PRODUCT_FAILURE";
export const SELL_PRODUCT_REQUEST = 'SELL_PRODUCT_REQUEST';
export const SELL_PRODUCT_SUCCESS = 'SELL_PRODUCT_SUCCESS';
export const SELL_PRODUCT_FAILURE = 'SELL_PRODUCT_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FILTER_PRODUCTS_REQUEST = 'FILTER_PRODUCTS_REQUEST';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAILURE = 'FILTER_PRODUCTS_FAILURE';
export const GET_ALL_TRANSACTIONS_REQUEST = 'GET_ALL_TRANSACTIONS_REQUEST';
export const GET_ALL_TRANSACTIONS_SUCCESS = 'GET_ALL_TRANSACTIONS_SUCCESS';
export const GET_ALL_TRANSACTIONS_FAILURE = 'GET_ALL_TRANSACTIONS_FAILURE';
export const GET_PRODUCT_BY_ID_REQUEST = 'GET_PRODUCT_BY_ID_REQUEST';
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS';
export const GET_PRODUCT_BY_ID_FAILURE = 'GET_PRODUCT_BY_ID_FAILURE';
export const GET_TRANSACTIONS_AND_PRODUCT_REQUEST = 'GET_TRANSACTIONS_AND_PRODUCT_REQUEST';
export const GET_TRANSACTIONS_AND_PRODUCT_SUCCESS = 'GET_TRANSACTIONS_AND_PRODUCT_SUCCESS';
export const GET_TRANSACTIONS_AND_PRODUCT_FAILURE = 'GET_TRANSACTIONS_AND_PRODUCT_FAILURE';
export const REMOVE_TRANSACTION = 'REMOVE_TRANSACTION'
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';